import React, { useState } from "react";

export const Carousel = ({ slides, dots }) => {
  const [currentSlide, setCurrentSlide] = useState(0);

  const goToSlide = (index) => {
    if (typeof window !== "undefined") {
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    }

    setCurrentSlide(index);
  };

  return (
    <div className="Carousel">
      <div className="Carousel__slide">{slides[currentSlide]}</div>
      <div className="Carousel__dots">
        {dots.map((dot, index) => {
          return (
            <div
              className={`Carousel__dots_item${
                index === currentSlide ? "-active" : ""
              }`}
              key={index}
              role="tab"
              tabIndex={index}
              onClick={() => goToSlide(index)}
              onKeyPress={() => {}}
            >
              {dot}
            </div>
          );
        })}
      </div>
    </div>
  );
};
