import React from "react";

import Page from "../../components/Page";
import SEO from "../../components/SEO";
import { Carousel } from "../../components/Carousel";

import Img1 from "../../images/adv/benefits/slider-images/Img_Advertisers_Benefits_1.jpg";
import Img2 from "../../images/adv/benefits/slider-images/Img_Advertisers_Benefits_2.jpg";
import Img3 from "../../images/adv/benefits/slider-images/Img_Advertisers_Benefits_3.jpg";
import Img4 from "../../images/adv/benefits/slider-images/Img_Advertisers_Benefits_4.jpg";
import Img5 from "../../images/adv/benefits/slider-images/Img_Advertisers_Benefits_5.jpg";

import Icon1 from "../../images/adv/benefits/slider-icons/IconBenefits_Advertisers_01.svg";
import Icon2 from "../../images/adv/benefits/slider-icons/IconBenefits_Advertisers_02.svg";
import Icon3 from "../../images/adv/benefits/slider-icons/IconBenefits_Advertisers_03.svg";
import Icon4 from "../../images/adv/benefits/slider-icons/IconBenefits_Advertisers_04.svg";
import Icon5 from "../../images/adv/benefits/slider-icons/IconBenefits_Advertisers_05.svg";

import { advertisers } from "../../helpers/navigation";

const BenefitsPage = () => {
  const slides = [
    <div className="Page_main">
      <div className="Page_main_block">
        <p>
          Гарантоване <strong>100%-ве</strong> регулярне{" "}
          <strong>інформаційне охоплення</strong> мешканців та гостей будинків,
          де розміщені монітори
        </p>
        <p>
          <strong>Покращена ефективність</strong> завдяки високій частоті
          трансляцій; <br /> Демонстрація реклами{" "}
          <strong>аудиторії з стабільним доходом.</strong>
        </p>
        <h6>Реклама повинна працювати!</h6>
      </div>
      <div className="Page_main_block">
        <img src={Img1} className="Page_main_block_img" alt="Img1" />
      </div>
    </div>,
    <div className="Page_main">
      <div className="Page_main_block">
        <p>
          <strong>Мережа інформаційно-рекламних моніторів</strong>
          <br />
          що швидко зростає, та охоплює все більше будинків.
        </p>
        <p>
          Наші партнери:
          <br />
          <ul>
            <li>
              <strong>ЖК РіверСтоун</strong>
            </li>
            <li>
              <strong>ЖК Річмонд</strong>
            </li>
            <li>
              <strong>Червонозоряний</strong>
            </li>
          </ul>
        </p>
        <h6>Збільшити аудиторію? Легко!</h6>
      </div>
      <div className="Page_main_block">
        <img src={Img2} className="Page_main_block_img" alt="Img1" />
      </div>
    </div>,
    <div className="Page_main">
      <div className="Page_main_block">
        <p>
          Поділ доби на <strong>три часові зони</strong> з різною частотою
          демонстрації
          <br />
          для <strong>підвищення ефективності реклами.</strong>
        </p>
        <p>
          <strong>Без нічних показів.</strong> Демонстрації з 6-ї ранку до 1-ї
          ночі.
          <br /> Можливість швидкого підключення реклами до{" "}
          <strong>будь-якої точки виводу.</strong>
        </p>
        <h6>Оплата за реальні покази!</h6>
      </div>
      <div className="Page_main_block">
        <img src={Img3} className="Page_main_block_img" alt="Img1" />
      </div>
    </div>,
    <div className="Page_main">
      <div className="Page_main_block">
        <p>
          Доступ у <strong>персональний кабінет</strong>
          <br />
          Керування налаштуваннями демонстрацій
        </p>
        <p>
          Отримання детальної статистики виводу
          <br />
          <strong>Контроль за витратами</strong>
        </p>
        <h6>Оновлення і розвиток системи</h6>
      </div>
      <div className="Page_main_block">
        <img src={Img4} className="Page_main_block_img" alt="Img1" />
      </div>
    </div>,
    <div className="Page_main">
      <div className="Page_main_block">
        <p>
          Створимо <strong>рекламний контент для Вас!</strong>
          <br />
          <strong>Швидко, якісно, недорого!</strong>
        </p>
        <p>
          Розробляємо привабливі рекламні оголошення для розміщення в системі.
          <br />
          Все, <strong>згідно ваших побажань!</strong>
        </p>
        <h6>Сервіс та якість!</h6>
      </div>
      <div className="Page_main_block">
        <img src={Img5} className="Page_main_block_img" alt="Img1" />
      </div>
    </div>,
  ];

  const sliderIcons = [<Icon1 />, <Icon2 />, <Icon3 />, <Icon4 />, <Icon5 />];
  let dots = [];

  for (let slideIndex = 0; slideIndex < slides.length; slideIndex++) {
    dots.push(<div className="Benefits_dots">{sliderIcons[slideIndex]}</div>);
  }

  return (
    <Page
      page="Benefits"
      pageName="Переваги"
      title="Переваги для Вас"
      parameters={advertisers}
    >
      <SEO title="Переваги" />

      <Carousel slides={slides} dots={dots} />
    </Page>
  );
};

export default BenefitsPage;
